import { ref, watch, computed } from '@vue/composition-api'
// Notification
import { debounce } from 'debounce'
import { startOfMonth, format } from 'date-fns'

export default function useAllTransaction() {
  // Use toast

  // Subtract 30 days from today's date
  const refTransactionTable = ref()
  const startDateFilter = ref(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const endDateFilter = ref(format(new Date(), 'yyyy-MM-dd'))
  const selectedTransactionType = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'date' },
    { key: 'transactionId', label: 'Transaction ID' },
    { key: 'type', label: 'Transaction Type' },
    { key: 'transactionableType', label: 'Description' },
    { key: 'username' },
    { key: 'groupName' },
    { key: 'competitionId', label: 'Showdown ID/Bonus Offer ID' },
    { key: 'fee' },
    { key: 'percentShared' },
    { key: 'competitionName', label: 'SHOWDOWN - BSB/ACC' },
    { key: 'leg' },
    { key: 'runner' },
    { key: 'placing' },
    { key: 'odds', label: 'Odds W/P' },
    { key: 'payout' },
    { key: 'purchaseAmount', label: 'Cash Amount' },
    { key: 'bonusCash', label: 'Bonus Cash' },
    { key: 'listBonusCashUsed', label: 'List Bonus Cash Used' },
    { key: 'betId' },
    // { key: 'status', label: 'Showdown Status' },
    { key: 'action' },
  ]

  const transactionTypes = [
    { label: 'All', value: null },
    { label: 'QuickPicks', value: 'quick-join-comp' },
    { label: 'Manual Picks', value: 'join-comp' },
    { label: 'Re-buy', value: 'rebuy' },
    { label: 'Re-use Betslip', value: 'reuse-bet-slip' },
    { label: 'Withdrawal', value: 'withdrawal' },
    { label: 'Refund Withdrawal', value: 'refund-withdrawal' },
    { label: 'Transfer Main Prize', value: 'transfer-main-prize' },
    { label: 'Transfer Entry Prize', value: 'transfer-entry-prize' },
    { label: 'Deposit', value: 'deposit' },
    { label: 'Update Balance', value: 'update-balance' },
    { label: 'Withdraw Voucher', value: 'withdrawal-voucher' },
    { label: 'Buy Voucher', value: 'buy-voucher' },
    { label: 'Voucher To Balance', value: 'voucher-to-balance' },
    { label: 'Refund Join Competition', value: 'refund-join-comp' },
    { label: 'Cancelled Betslip', value: 'cancelled-betslip' },
    { label: 'Promo', value: 'promo' },
    { label: 'Free Bet Entry', value: 'free-bet-entry' },
    { label: 'Cancel Promo', value: 'cancel-promo' },
    { label: 'Fee Blueshyft', value: 'fee' },
    { label: 'Transfer To UPCOZ Wallet', value: 'transfer-from-wallet-to-wallet' },
    { label: 'Receive transfer UPC Wallet', value: 'receive-transfer' },
    { label: 'Deposit Group', value: 'deposit-group' },
    { label: 'Join Group', value: 'join-group' },
    { label: 'User Receive Distribute Group', value: 'user-receive-distribute-group' },
    { label: 'User Receive Share from Group', value: 'receive-share-from-group' },
    { label: 'Distribute in group', value: 'distribute-group' },
    { label: 'Group transfer user share', value: 'group-transfer-user-share' },
    { label: 'Accept join group', value: 'accept-join-group' },
    { label: 'Buy Bet Yourself', value: 'single-buy-bet-yourself-betslip' },
    { label: 'Transfer Bet Yourself Prize', value: 'transfer-bet-yourself-prize' },
    { label: 'Refund Bet Yourself', value: 'refund-bet-yourself-betslip' },
    { label: 'Revert Bet Yourself Prize', value: 'revert-bet-yourself-prize' },
  ]

  const statusOptions = ['All', 'Pending', 'Reject', 'Approve', 'Complete']

  const perPage = ref(100)
  const totalTransactions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const isSortDirDesc = ref(false)
  const sortBy = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTransactionTable.value ? refTransactionTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTransactions.value,
    }
  })

  const refetchData = () => {
    refTransactionTable.value.refresh()
  }

  watch([currentPage, perPage, selectedTransactionType], () => {
    refetchData()
  })

  watch([endDateFilter, startDateFilter], () => {
    if (endDateFilter && startDateFilter) {
      refetchData()
    }
  })

  watch(
    [searchQuery],
    debounce(() => {
      totalTransactions.value = 0
      refetchData()
    }, 700),
  )

  const resolveTransactionStatusVariant = status => {
    switch (status) {
      case 'pending':
        return 'primary'
      case 'reject':
        return 'danger'
      case 'approve':
        return 'info'
      case 'complete':
        return 'success'
      default:
        return ''
    }
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalTransactions,
    dataMeta,
    perPageOptions,
    searchQuery,
    refTransactionTable,
    isSortDirDesc,
    sortBy,

    startDateFilter,
    endDateFilter,

    statusOptions,

    selectedTransactionType,
    transactionTypes,

    resolveTransactionStatusVariant,
  }
}
