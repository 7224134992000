<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div
        id="filter"
        class="m-2"
      >
        <!-- Table Top -->

        <b-row class="mb-2">
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>Filter by Transaction Type</label>
            <v-select
              v-model="selectedTransactionType"
              :options="transactionTypes"
              :reduce="transaction => transaction.value"
            />
          </b-col>
          <!-- <b-col
            cols="12"
            md="6"
            class=" d-flex flex-column align-items-end"
          >
            <label class="hidden-text">Export ABA File</label>

            <b-button
              variant="success"
              class="w-fit"
            >
              Export ABA File
            </b-button>
          </b-col> -->
        </b-row>

        <b-row>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="6"
            class="mb-md-0 d-flex align-items-center justify-content-center"
          >
            <span class="w-10">From:</span>

            <flat-pickr
              id="startDateFilter"
              v-model="startDateFilter"
              class="form-control ml-2"
              :config="config"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 d-flex align-items-center justify-content-center mt-1 mt-md-0"
          >
            <span class="w-10">To:</span>

            <flat-pickr
              id="endDateFilter"
              v-model="endDateFilter"
              class="form-control ml-2"
              :config="config"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center justify-content-end mt-1">
              <label>Show</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>transactions</label>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive">
        <b-table
          ref="refTransactionTable"
          :items="getListTransaction"
          :fields="tableColumns"
          responsive
          show-empty
          primary-key="transaction-table"
          style="height: calc(100vh - 400px); min-height: 400px;"
        >
          <template #empty>
            <div
              v-if="loadingTransactionList"
              class="text-center my-2"
            >
              <p>Loading... Please wait</p>
            </div>

            <div
              v-else-if="!loadingTransactionList && totalTrans === 0"
              class="text-center my-2"
            >
              No matching records found
            </div>
          </template>
          <!-- Column: date -->
          <template #cell(date)="{ item }">
            <span class="align-text-top text-capitalize">{{ formatDateTimeLocal(item.date) }}</span>
          </template>
          <!-- Column: TRANSACTION ID -->
          <template #cell(transactionId)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.transactionId }}</span>
          </template>

          <!-- Column: type -->
          <template #cell(type)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.type }}</span>
          </template>
          <!-- Column: description -->
          <template #cell(transactionableType)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.type === 'fee' ? item.metaData.paymentId : description(item) }}</span>
          </template>
          <!-- Column: username -->
          <template #cell(username)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.groupId && (item.type !== 'user-receive-distribute-group' && item.type !== "receive-share-from-group" && item.type !== 'accept-join-group' && item.type !== 'join-group' && item.type !=='group-transfer-user-share') ? '': item.username ? item.username : 'N/A' }}</span>
          </template>
          <!-- Column: competitionId -->
          <template #cell(competitionId)="{ item }">
            <span class="align-text-top text-capitalize">{{
              item.type === 'deposit' || item.type === 'withdrawal' || item.type === 'fee' || item.type === 'receive-transfer' || item.type === 'transfer-from-wallet-to-wallet' || (item.groupId && item.type !== 'quick-join-comp')? 'N/A' : item.transactionableId ? item.transactionableId : 'N/A'
            }}</span>
          </template>
          <!-- Column: leg -->
          <template #cell(leg)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.leg ? item.leg : 'N/A' }}</span>
          </template>
          <!-- Column: percentShared -->
          <template #cell(percentShared)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.percentShared ? `${item.percentShared}%` : '' }}</span>
          </template>
          <!-- Column: runner -->
          <template #cell(runner)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.runner ? item.runner : 'N/A' }}</span>
          </template>
          <!-- Column: placing -->
          <template #cell(placing)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.placing ? item.placing : 'N/A' }}</span>
          </template>
          <!-- Column: odds -->
          <template #cell(odds)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.odds ? item.odds : 'N/A' }}</span>
          </template>
          <!-- Column: payout -->
          <template #cell(payout)="{ item }">
            <span class="align-text-top text-capitalize">{{
              item.type === 'cancelled-betslip' || item.type === 'refund-bet-yourself-betslip'
                ? checkRefund(item)
                :item.metaData.amount && (item.type === 'transfer-main-prize' || item.type === 'transfer-entry-prize') && item.metaData.prizeType !== 'Bonus-Cash' || item.type === 'user-receive-distribute-group' || item.type === "receive-share-from-group" || item.type === 'accept-join-group' || item.type === 'transfer-bet-yourself-prize'
                  ? formatCurrencyBuiltInFunc(item.metaData.amount)
                  : 'N/A'
            }}</span>
          </template>
          <!-- Column: fee -->
          <template #cell(fee)="{ item }">
            <span class="align-text-top text-capitalize">{{ convertFee(item)

            }}</span>
          </template>
          <!-- Column: cash amount -->
          <template #cell(purchaseAmount)="{ item }">
            <span class="align-text-top text-capitalize">{{ purchaseAmount(item) }}</span>
          </template>
          <!-- Column: bonus cash -->
          <template #cell(bonusCash)="{item}">
            <span class="align-text-top text-capitalize">{{ item.metaData.prizeType === 'Bonus-Cash' ? formatCurrencyBuiltInFunc(item.metaData.amount) : formattedBonusCashColumn(item) }}</span>
          </template>
          <!-- Column: bonus cash used -->
          <template #cell(listBonusCashUsed)="{item}">
            <span class="align-text-top text-capitalize">{{ item.metaData && item.metaData.usedBonus && item.metaData.usedBonus.length ? formatCurrencyBuiltInFunc(item.metaData.usedBonus.reduce((acc,item) => acc + item.used ,0)) : 'N/A' }}</span>
          </template>
          <!-- Column: betId -->
          <template #cell(betId)="{ item }">
            <span class="align-text-top text-capitalize">{{
              item.metaData.betSlipId ? item.metaData.betSlipId : 'N/A'
            }}</span>
          </template>

          <!-- Column: competitionName -->
          <template #cell(competitionName)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.type === 'fee' ? 'Deposit Method - Blueshyft' : showdownName(item) }}</span>
          </template>

          <!-- Column: actions -->
          <template #cell(action)="{ item }">
            <b-dropdown

              variant="link"
              no-caret
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="!item.refund && ( item.type === 'join-comp'
                  || item.type === 'quick-join-comp'
                  || item.type === 'reuse-bet-slip')"
                @click="showModal(TransactionAction.REFUND, item.transactionId)"
              >
                <feather-icon icon="RefreshCwIcon" />
                <span class="align-middle ml-50">Refund</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
              >
                <span class="align-middle ml-50">No actions available</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} transactions
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="modal"
      :title="modal.title"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="isSubmitting"
      @ok="handleSubmitModal"
    >
      <b-card-text class="text-title">
        {{ modal.content }}
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="isSubmitting" />
        <span v-else>Submit</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
  // BButton,
  BCardText,
  VBModal,

} from 'bootstrap-vue'
import { TransactionAction } from '@core/utils/enum/transaction'
import vSelect from 'vue-select'
import { mapState, mapActions } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import useToastHandler from '@/services/toastHandler'
import {
  formatDate,
  formatDateTimeLocal,
  formatCurrencyBuiltInFunc,
  convertTimeEnd,
  convertTimeStart,
} from '@/helpers/utils'
import Spinner from '@core/components/Spinner'
import useAllTransactions from './useAllTransaction.js'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
    BDropdown,
    BDropdownItem,
    flatPickr,
    // BButton,
    BCardText,
    Spinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      Spinner,
      loadingExportFile: null,
      filterStatus: 'All',
      actionList: ['Approve', 'Decline', 'Review'],
      config: {
        dateFormat: 'Y-m-d',
        maxDate: new Date(),
      },
      modal: {
        title: '',
        content: '',
      },
      action: '',
      transactionIdRefund: null,
    }
  },
  computed: {
    ...mapState({
      withdrawStore: s => s.transaction.withdrawList,
    }),
    ...mapState('transaction/detail', ['loadingTransactionList', 'totalTrans', 'loadingRefundTransaction']),

    isSubmitting() {
      return this.loadingRefundTransaction
    },

    description() {
      return item => {
        if (item.competitionType === 'sport-tipping') return 'Tipping'
        if (item.type === 'deposit') {
          return (
            item.metaData.paymentId
            || item.metaData.googlePayTransactionId
            || item.metaData.applePayTransactionId
            || 'N/A'
          )
        }
        if (item.type === 'withdrawal') {
          return 'Bank Account'
        }
        if (item.type === 'refund-bet-yourself-betslip' || item.type === 'single-buy-bet-yourself-betslip') return item.description
        return item.transactionableType || 'N/A'
      }
    },

    showdownName() {
      return item => {
        if (item.type === 'deposit') {
          return `Deposit Method - ${item.metaData.depositType}`
        }
        if (item.type === 'withdrawal') {
          return `${item.bsb} - ${item.number}`
        }
        return item.competitionName || 'N/A'
      }
    },

    purchaseAmount() {
      return item => {
        if (item.type === 'deposit' || item.type === 'withdrawal' || item.type === 'refund-join-comp' || item.type === 'refund-withdrawal' || item.type === 'update-balance' || item.type === 'receive-transfer') {
          return formatCurrencyBuiltInFunc(item.metaData.amount)
        }

        if (
          item.type === 'join-comp'
          || item.type === 'quick-join-comp'
          || item.type === 'reuse-bet-slip'
          || item.type === 'rebuy'
          || item.type === 'buy-voucher'
        ) {
          return formatCurrencyBuiltInFunc(item.metaData.oldBalance - item.metaData.balance)
        }
        return 'N/A'
      }
    },
    heightFilter() {
      return document.getElementById('filter').clientHeight
    },
  },

  methods: {
    ...mapActions({
      asyncTransactionList: 'transaction/detail/asyncTransactionList',
      handleRefundTransaction: 'transaction/detail/handleRefundTransaction',

    }),

    formattedBonusCashColumn(data) {
      if (data.type === 'cancelled-betslip') {
        if (!data.metaData || !data.metaData.refundBonus) return 'N/A'
        return formatCurrencyBuiltInFunc(data.metaData.refundBonus.reduce((acc, item) => acc + item.used, 0))
      }

      if (data.type === 'promo') {
        return formatCurrencyBuiltInFunc(data.metaData.amount)
      }
      if (data.type === 'cancel-promo') {
        return formatCurrencyBuiltInFunc(data.metaData.remain)
      }
      return 'N/A'
    },
    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },

    showModal(type, id) {
      this.action = type
      if (type === TransactionAction.REFUND) {
        this.transactionIdRefund = id
        this.setModal('Refund Transaction', `Are you sure you want to refund transaction ID: ${id}?`)
      }

      this.$refs.modal.show()
    },

    async handleRefund() {
      const res = await this.handleRefundTransaction(this.transactionIdRefund)
      if (res) {
        this.transactionIdRefund = null
        this.refTransactionTable.refresh()
        this.showToastSuccess('Success', 'Refund successfully!')
      }
    },

    async handleSubmitModal(event) {
      event.preventDefault()

      if (this.action === TransactionAction.REFUND) await this.handleRefund()

      if (!this.isSubmitting) {
        this.$nextTick(() => {
          this.$refs.modal.hide()
        })
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    async getListTransaction() {
      const data = await this.asyncTransactionList({
        limit: this.perPage,
        page: this.currentPage,
        'date[startDate]': convertTimeStart(this.startDateFilter),
        'date[endDate]': convertTimeEnd(this.endDateFilter),
        type: this.selectedTransactionType,
        includeHidden: 1,
      })
      this.totalTransactions = this.totalTrans
      return data
    },
    formatValueAction(val) {
      if (!val) return ''
      const valNumber = val.slice(0, 1)
      return this.actionList[Number(valNumber)]
    },
    checkRefund(item) {
      const data = item.metaData.toWallet
      return this.formatCurrencyBuiltInFunc(data.balance - data.oldBalance)
    },
    convertFee(item) {
      if (item.type === 'revert-bet-yourself-prize') {
        return this.formatCurrencyBuiltInFunc(item.metaData.wallet.oldBalance - item.metaData.wallet.balance)
      }
      return item.type === 'single-buy-bet-yourself-betslip'
        ? formatCurrencyBuiltInFunc(item.metaData.stake)
        : (item.type === 'fee' || item.type === 'transfer-from-wallet-to-wallet' || item.type === 'deposit-group' || item.type === 'join-group' || item.type === 'group-transfer-user-share' || item.type === 'distribute-group') && item.metaData.amount
          ? formatCurrencyBuiltInFunc(item.metaData.amount)
          : 'N/A'
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalTransactions,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      searchQuery,
      refTransactionTable,
      sortBy,
      startDateFilter,
      endDateFilter,

      statusOptions,
      selectedTransactionType,
      transactionTypes,
      refetchData,
      resolveTransactionStatusName,
      resolveTransactionStatusVariant,
    } = useAllTransactions()

    return {
      // Sidebar
      tableColumns,
      perPage,
      currentPage,
      totalTransactions,
      dataMeta,
      perPageOptions,
      searchQuery,
      refTransactionTable,
      isSortDirDesc,
      sortBy,
      selectedTransactionType,
      startDateFilter,
      endDateFilter,
      transactionTypes,
      statusOptions,

      resolveTransactionStatusName,
      resolveTransactionStatusVariant,

      showToastError,
      showToastSuccess,
      refetchData,
      formatDate,
      formatDateTimeLocal,
      formatCurrencyBuiltInFunc,
      TransactionAction,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.dark-layout
  .flatpickr-calendar
  .flatpickr-days
  .flatpickr-day:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  background: #ccc;
}

.per-page-selector {
  width: 90px;
}

.hidden-text {
  opacity: 0;
  visibility: hidden;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.w-fit {
  width: fit-content;
}
.card-body {
  @media screen and (max-width: 640px) {
    padding-block: 0 !important;
  }
}
.w-10 {
  display: inline-block;
  width: 50px;
}

.text-title {
  color:#000;
}
</style>
<!--
<style lang="scss" scoped>
.dark-layout
  .flatpickr-calendar
  .flatpickr-days
  .flatpickr-day:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  background: #ccc;
}

</style> -->
